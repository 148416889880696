import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap'
import logo from './images/logo192.png'

function Menu() {

    return (
        <>
            <Navbar variant='dark' bg='dark' sticky='top'>
                <LinkContainer to="/" style={{ marginLeft: '20px', marginRight: '20px' }}><Navbar.Brand><img src={logo} alt="쿠키런 브레이버스 정보모음" style={{width:"2rem",marginRight:"1rem"}} /><span className="d-md-inline d-none">쿠키런 브레이버스 카드정보</span></Navbar.Brand></LinkContainer>
                <Nav className='me-auto'>
                    <LinkContainer to="/cardinfo"><Nav.Link>카드 정보 보기</Nav.Link></LinkContainer>
                    <LinkContainer to="/decksimulator"><Nav.Link>덱 시뮬레이터</Nav.Link></LinkContainer>
                </Nav>
            </Navbar>
        </>
    )

}

export default Menu;