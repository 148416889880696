import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Menu from './Menu'
// import Home from './Home'
import CardInfo from './CardInfo'
import DeckSimul from './DeckSimul'

function App() {

  return (
    <BrowserRouter>
      <Menu />
      <Routes>
        <Route path="/" element={<CardInfo />} />
        <Route path="/cardinfo" element={<CardInfo />} />
        <Route path="/decksimulator" element={<DeckSimul />} />
        <Route path="/decksimulator/:deckcode" element={<DeckSimul />} />
        <Route path="*" element={<CardInfo />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
