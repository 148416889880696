import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import cardData from "./carddata.json"
import { sortName, sortCardType, sortLevel, sortColor } from './sort'
import CardInfo from "./CardInfo"
import imgRed from "./images/red.png"
import imgYellow from "./images/yellow.png"
import imgGreen from "./images/green.png"

const sortedCards = cardData.toSorted((a, b) => (a.index - b.index))
sortedCards.forEach(card => card.count = 0)
let codeLoaded = false;

function DeckSimul() {

    const [deck, setDeck] = useState([])            // card index 배열
    const [deckAnalysis, setAnalysis] = useState({})
    const [alertShow, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState(["", ""])

    const [viewMode, setViewMode] = useState(0) // 0 텍스트 1 카드

    const [tooltipCard, setTooltipCard] = useState({})
    const [viewTooltip, setViewTooltip] = useState(false)
    const [tooltipCoord, setTooltipCoordR] = useState([0, 0])
    const setTooltipCoord = function(coord) {
        if(coord[1] + 558 > document.documentElement.clientHeight) coord[1] = document.documentElement.clientHeight - 558;
        setTooltipCoordR(coord)
    }

    const [randomizedDeck, setRandomizedDeck] = useState([])
    const [drawCount, setDrawCount] = useState(0)


    const deckCode = useParams()
    if (!codeLoaded && deckCode.deckcode) setTimeout(() => { getFromCode(deckCode.deckcode) }, 50)
    function alertMsg(msg, type = "danger") {
        setAlertMessage([msg, type])
        setAlert(true)
        setTimeout(() => { setAlert(false) }, 2500)
    }

    function addCardToDeck(index) {
        if (deck.length < 60) {
            if (deckAnalysis.flipCount === 16 && sortedCards[index].flip) {
                alertMsg("FLIP 카드는 16장까지만 덱에 넣을 수 있습니다.")
                return false;
            }
            if (sortedCards[index].count && sortedCards[index].count === 4) {
                alertMsg("같은 번호의 카드는 4장까지만 덱에 넣을 수 있습니다.")
                return false;
            }
            if (sortedCards[index].type !== "COOKIE" && deckAnalysis.typeCount.cookie === 0 && deck.length === 59) {
                alertMsg("덱에 최소 1장의 쿠키를 포함해야 합니다.")
                return false;
            }
            const newDeck = [...deck]
            newDeck.push(index)
            newDeck.sort()
            setDeck(newDeck)
            return true;
        }
        alertMsg("덱에는 60장까지만 넣을 수 있습니다.")
        return false;
    }
    function deleteCardFromDeck(index) {
        if (deck.indexOf(index) > -1) {
            const newDeck = [...deck]
            newDeck.splice(deck.indexOf(index), 1)
            setDeck(newDeck)
            sortedCards[index].count--;
            resetDraw()
            return true;
        }
        return false;
    }

    useEffect(() => {
        const result = {
            typeCount: {
                cookie: 0,
                item: 0,
                trap: 0,
                stage: 0
            },
            colorCount: {
                red: 0,
                yellow: 0,
                green: 0
            },
            flipCount: 0,
            species: []
        }
        deck.forEach(index => {
            switch (sortedCards[index].type) {
                case "COOKIE":
                    result.typeCount.cookie++;
                    break;
                case "ITEM":
                    result.typeCount.item++;
                    break;
                case "TRAP":
                    result.typeCount.trap++;
                    break;
                case "STAGE":
                    result.typeCount.stage++;
                    break;
                default:
            }
            switch (sortedCards[index].color) {
                case "레드":
                    result.colorCount.red++;
                    break;
                case "옐로":
                    result.colorCount.yellow++;
                    break;
                case "그린":
                    result.colorCount.green++;
                    break;
                default:
            }
            if (sortedCards[index].flip) result.flipCount++;

            if (!result.species.includes(sortedCards[index])) { result.species.push(sortedCards[index]); sortedCards[index].count = 1 }
            else sortedCards[index].count++
        })
        result.species.sort(sortName)
        result.species.sort(sortColor)
        result.species.sort(sortLevel)
        result.species.sort(sortCardType)

        setAnalysis(result)
    }, [deck])

    function printCard(card) {
        return (
            <Row
                style={{ color: card.color === "레드" ? "red" : card.color === "옐로" ? "#FFB813" : "green", cursor: "pointer" }}
                onClick={() => { deleteCardFromDeck(card.index); setViewTooltip(false); }}
                onMouseOver={() => { setTooltipCard(card); setViewTooltip(true); }}
                onMouseOut={() => { setTooltipCard({}); setViewTooltip(false); }}
                onMouseMove={(e) => { setTooltipCoord([e.clientX, e.clientY]) }}
            >
                {card.count}x {card.name} {card.flip ? "(F)" : ""}
            </Row>
        )
    }

    function makeCode() {

        const cardsByCount = [[], [], [], []] // 4장,3장,2장,1장

        sortedCards.forEach(card => {
            if (card.count > 0) cardsByCount[4 - card.count].push(card.index)
        })

        const binary = [...cardsByCount[0], 65535, ...cardsByCount[1], 65535, ...cardsByCount[2], 65535, ...cardsByCount[3]]
        var strFrombinary = ""
        binary.forEach(i => {
            strFrombinary += String.fromCharCode(parseInt(i / 256), i % 256)
        })

        const code = btoa(strFrombinary).replaceAll("/", "_")
        navigator.clipboard.writeText(document.location.href.split("decksimulator")[0] + "decksimulator/" + code)
        alertMsg("클립보드에 복사되었습니다.", "success")
    }

    function makeText() {
        let result = ""

        if (deckAnalysis.typeCount.cookie) result += "COOKIE (" + deckAnalysis.typeCount.cookie + ")\n"
        deckAnalysis.species?.forEach(card => {
            if (card.type === "COOKIE") result += card.count + "x " + card.name + (card.flip ? " (F) (" : " (") + card.serial + ")\n"
        })
        if (deckAnalysis.typeCount.item) result += "\nITEM (" + deckAnalysis.typeCount.item + ")\n"
        deckAnalysis.species?.forEach(card => {
            if (card.type === "ITEM") result += card.count + "x " + card.name + (card.flip ? " (F) (" : " (") + card.serial + ")\n"
        })
        if (deckAnalysis.typeCount.trap) result += "\nTRAP (" + deckAnalysis.typeCount.trap + ")\n"
        deckAnalysis.species?.forEach(card => {
            if (card.type === "TRAP") result += card.count + "x " + card.name + (card.flip ? " (F) (" : " (") + card.serial + ")\n"
        })
        if (deckAnalysis.typeCount.stage) result += "\nSTAGE (" + deckAnalysis.typeCount.stage + ")\n"
        deckAnalysis.species?.forEach(card => {
            if (card.type === "STAGE") result += card.count + "x " + card.name + (card.flip ? " (F) (" : " (") + card.serial + ")\n"
        })

        navigator.clipboard.writeText(result)
        alertMsg("클립보드에 복사되었습니다.", "success")
    }

    function resetDraw() {

        function shuffle(array) {
            for (let index = array.length - 1; index > 0; index--) {
                const randomPosition = Math.floor(Math.random() * (index + 1));

                const temporary = array[index];
                array[index] = array[randomPosition];
                array[randomPosition] = temporary;
            }
        }

        setDrawCount(0);

        const randomDeck = [...deck]
        shuffle(randomDeck)
        setRandomizedDeck([...randomDeck])

    }

    function getFromCode(code) {
        codeLoaded = true;
        sortedCards.forEach(card => card.count = 0)
        code = code.replaceAll("_", "/")
        try {
            code = atob(code);
        } catch (err) {
            alertMsg("읽을 수 없는 덱 코드입니다.")
            return;
        }
        const binary = []
        let temp = ""
        for (let c of code) {
            if (temp === "") temp = c;
            else {
                binary.push(temp.charCodeAt(0) * 256 + c.charCodeAt(0))
                temp = ""
            }
        }

        let wallCount = 0;
        binary.forEach(index => {
            if (index === 65535) {
                wallCount++;
                return;
            }
            if (index >= sortedCards.length) {
                wallCount = 4
                return;
            }
        })
        if (wallCount !== 3) {
            alertMsg("읽을 수 없는 덱 코드입니다.")
            return;
        }
        let i = 0;
        const newDeck = [];

        while (binary[i] !== 65535) {
            newDeck.push(binary[i])
            newDeck.push(binary[i])
            newDeck.push(binary[i])
            newDeck.push(binary[i])
            sortedCards[binary[i]].count = 4
            i++
        }
        i++
        while (binary[i] !== 65535) {
            newDeck.push(binary[i])
            newDeck.push(binary[i])
            newDeck.push(binary[i])
            sortedCards[binary[i]].count = 3
            i++
        }
        i++
        while (binary[i] !== 65535) {
            newDeck.push(binary[i])
            newDeck.push(binary[i])
            sortedCards[binary[i]].count = 2
            i++
        }
        i++
        while (i !== binary.length) {
            newDeck.push(binary[i])
            sortedCards[binary[i]].count = 1
            i++
        }

        alertMsg("덱을 불러왔습니다.", "success")

        setDeck(newDeck)

    }

    return (
        <>
            <Container fluid style={{ padding: "0px 2rem" }}>
                <Alert show={alertShow} variant={alertMessage[1]} style={{ position: "fixed", width: "", right: 0 }} >
                    {alertMessage[0]}
                </Alert>
                <Row>
                    <Col lg={7}>
                        <Row style={{ userSelect: "none" }}>
                            <Col xs={6} style={{ margin: "0.5rem", verticalAlign: "middle" }}>
                                <Row style={{ margin: "0.5rem", verticalAlign: "middle" }}>
                                    TOTAL : {deck.length} /&nbsp;
                                    <img src={imgRed} alt="RED" style={{ width: "1.5rem", padding: "0", marginRight: "0.4rem" }} /> : {deckAnalysis.colorCount?.red} /&nbsp;
                                    <img src={imgYellow} alt="YELLOW" style={{ width: "1.5rem", padding: "0", marginRight: "0.4rem" }} /> : {deckAnalysis.colorCount?.yellow} /&nbsp;
                                    <img src={imgGreen} alt="GREEN" style={{ width: "1.5rem", padding: "0", marginRight: "0.4rem" }} /> : {deckAnalysis.colorCount?.green} /
                                    FLIP : {deckAnalysis.flipCount}
                                </Row>
                                <Row style={{ margin: "0.5rem", verticalAlign: "middle" }}>
                                    COOKIE : {deckAnalysis.typeCount?.cookie} /&nbsp;
                                    ITEM : {deckAnalysis.typeCount?.item} /&nbsp;
                                    TRAP : {deckAnalysis.typeCount?.trap} /&nbsp;
                                    STAGE : {deckAnalysis.typeCount?.stage}
                                </Row>
                            </Col>
                            <Col xs={3} style={{ padding: "0.5rem" }}>
                                <Button className="float-end" style={{margin:'0'}} onClick={() => { setViewMode(viewMode===0?1:0) }}>{viewMode===0?"카드로 보기":"텍스트로 보기"}</Button>
                            </Col>
                            <Col xs={2} style={{ padding: "0.5rem" }}>
                                <Button className="float-end" onClick={() => { setDeck([]); sortedCards.map(card => card.count = 0) }}>덱 초기화</Button>
                            </Col>
                        </Row>
                        <Row style={{ userSelect: "none" , display:viewMode===0?"flex":"none"}}>
                            <Col>
                                <Row><h5>COOKIE ({deckAnalysis.typeCount?.cookie})</h5></Row>
                                {
                                    deckAnalysis.species?.map((card) => {
                                        if (card.type !== "COOKIE") return "";
                                        return printCard(card)
                                    })
                                }
                            </Col>
                            <Col>
                                <Row><h5>ITEM ({deckAnalysis.typeCount?.item})</h5></Row>
                                {
                                    deckAnalysis.species?.map((card) => {
                                        if (card.type !== "ITEM") return "";
                                        return printCard(card)
                                    })
                                }
                            </Col>
                            <Col>
                                <Row><h5>TRAP ({deckAnalysis.typeCount?.trap})</h5></Row>
                                {
                                    deckAnalysis.species?.map((card) => {
                                        if (card.type !== "TRAP") return "";
                                        return printCard(card)
                                    })
                                }
                            </Col>
                            <Col>
                                <Row><h5>STAGE ({deckAnalysis.typeCount?.stage})</h5></Row>
                                {
                                    deckAnalysis.species?.map((card) => {
                                        if (card.type !== "STAGE") return "";
                                        return printCard(card)
                                    })
                                }
                            </Col>
                        </Row>
                        <Row style={{ userSelect: "none" , display:viewMode===1?"flex":"none"}}>
                            {
                                (function () {
                                    let count = 0;
                                    let index = 0;
                                    const cols = [[],[],[],[]]
                                    deckAnalysis.species?.forEach(card => {
                                        for(let i =0;i<card.count;i++) cols[index].push(card)
                                        count += card.count
                                        if(count >= 15) {
                                            count = 0;
                                            index++;
                                        }
                                    })
                                    return (
                                        <>
                                            {
                                                cols.map(cards => {
                                                    return (
                                                        <Col>
                                                            {
                                                                cards.map((card, index, cards) => {
                                                                    return (
                                                                        <img 
                                                                            src={require('.' + 
                                                                                ((index !== cards.length-1) ?
                                                                                card.image[0].replace("/card/","/cardtop/") :
                                                                                card.image[0])
                                                                                )} 
                                                                            alt={card.name}
                                                                            style={{width:"100%"}}
                                                                            onClick={() => { deleteCardFromDeck(card.index); setViewTooltip(false); }}
                                                                            onMouseOver={() => { setTooltipCard(card); setViewTooltip(true); }}
                                                                            onMouseOut={() => { setTooltipCard({}); setViewTooltip(false); }}
                                                                            onMouseMove={(e) => { setTooltipCoord([e.clientX, e.clientY]) }}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                })()
                            }
                        </Row>
                        <Row style={{ margin: "4rem 3rem 0 3rem" }}>
                            <Col style={{ padding: "0 2rem" }}>
                                <Row>
                                    <Button disabled={deck.length !== 60} onClick={() => { makeCode(); }}>덱 주소 복사하기</Button>
                                </Row>
                            </Col>
                            <Col style={{ padding: "0 2rem" }}>
                                <Row>
                                    <Button disabled={deck.length !== 60} onClick={() => { makeText(); }}>덱 텍스트로 복사하기</Button>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "0.5rem", padding: "0 5rem" }}>
                            <Col xs="8" style={{ padding: "0 0" }}>
                                <Row style={{ margin: "0" }}>
                                    <Button disabled={deck.length !== 60} onClick={() => { resetDraw(); setDrawCount(6); }}>카드 6장 뽑아보기</Button>
                                </Row>
                            </Col>
                            <Col xs="2" style={{ padding: "0 0 0 1rem" }}>
                                <Row style={{ margin: "0" }}>
                                    <Button disabled={deck.length !== 60} onClick={() => { setDrawCount(drawCount+1); }}>+1</Button>
                                </Row>
                            </Col>
                            <Col xs="2" style={{ padding: "0 0 0 1rem" }}>
                                <Row style={{ margin: "0" }}>
                                    <Button disabled={deck.length !== 60} onClick={() => { setDrawCount(drawCount+2); }}>+2</Button>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{marginTop:"1rem",visibility:drawCount>0?"visible":"hidden"}}>
                            <Col>
                                {
                                    Array(drawCount).fill().map((v,i) => i+1).map(n => {
                                        return (
                                            <img 
                                                style={{ width: "8rem" }} 
                                                src={require("." + sortedCards[randomizedDeck[n]].image[0])} 
                                                alt={sortedCards[randomizedDeck[n]].name}
                                                onMouseOver={() => { setTooltipCard(sortedCards[randomizedDeck[n]]); setViewTooltip(true); }}
                                                onMouseOut={() => { setViewTooltip(false); }}
                                                onMouseMove={(e) => { setTooltipCoord([e.clientX, e.clientY]) }}
                                            />
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={5} style={{ height: "90vh", overflow: "auto" }}>
                        <CardInfo imported={true} addCard={addCardToDeck} />
                    </Col>
                </Row>
            </Container>
            <div style={{ position: "fixed", display: viewTooltip ? "block" : "none", left: tooltipCoord[0] + 20, top: tooltipCoord[1] + 10 }} >
                {tooltipCard.image ? (<img style={{ width: "400px", zIndex: "3" }} src={require("." + tooltipCard.image[0])} alt={tooltipCard.name} />) : ""}
            </div>
        </>
    )

}

export default DeckSimul;