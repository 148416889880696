import { Container, Row, Col, Button, Form, ButtonGroup, ToggleButton, Table, Modal } from "react-bootstrap";
import { useState, useEffect } from 'react'
import { sortName, sortNameR, sortCardType, sortCardTypeR, sortLevel, sortLevelR, sortColor, sortHP, sortHPR } from './sort'
import cardData from "./carddata.json"
import imgRed from "./images/red.png"
import imgYellow from "./images/yellow.png"
import imgGreen from "./images/green.png"
import imgMix from "./images/mix.png"

var sorted = 8;

function CardInfo(props) {

    const [cardName, setCardName] = useState("")
    const [cardType, setCardType] = useState([false, false, false, false]) // 쿠키, 아이템, 트랩, 스테이지
    const [cookieLevel, setCookieLevel] = useState([false, false, false]) // 1, 2, 3
    const [cardColor, setCardColor] = useState([false, false, false]) // 레드, 옐로, 그린
    const [costColor, setCostColor] = useState([false, false, false, false]) // 레드, 옐로, 그린, 믹스
    const [isFlip, setFlip] = useState(0) // 모두, 있음, 없음
    const [searchTarget, setSearchTarget] = useState([false, false, false, false, false, false]) // 이름, 종류, 레벨, 색상, 코스트 색상, 플립
    const [cardList, setCardList] = useState(cardData)
    const [viewCardModal, setViewCardModal] = useState(false)
    const [currentCard, setCurrentCard] = useState(0)
    const [showDesc, setDesc] = useState(true)

    const [tooltipCard, setTooltipCard] = useState({})
    const [viewTooltip, setViewTooltip] = useState(false)
    const [tooltipCoord, setTooltipCoordR] = useState([0, 0])
    const setTooltipCoord = function(coord) {
        if(coord[1] + 558 > document.documentElement.clientHeight) coord[1] = document.documentElement.clientHeight - 558;
        setTooltipCoordR(coord)
    }


    useEffect(() => {
        function getSearchedCards() {
            const searchedCards = cardData.filter((card) => {
                if (cardName !== "" && !card.name.includes(cardName)) return false;
                if (card.type === "COOKIE" && searchTarget[1] && !cardType[0]) return false;
                if (card.type === "ITEM" && searchTarget[1] && !cardType[1]) return false;
                if (card.type === "TRAP" && searchTarget[1] && !cardType[2]) return false;
                if (card.type === "STAGE" && searchTarget[1] && !cardType[3]) return false;
                if (card.type === "COOKIE" && card.level === "1" && searchTarget[2] && !cookieLevel[0]) return false;
                if (card.type === "COOKIE" && card.level === "2" && searchTarget[2] && !cookieLevel[1]) return false;
                if (card.type === "COOKIE" && card.level === "3" && searchTarget[2] && !cookieLevel[2]) return false;
                if (card.color === "레드" && searchTarget[3] && !cardColor[0]) return false;
                if (card.color === "옐로" && searchTarget[3] && !cardColor[1]) return false;
                if (card.color === "그린" && searchTarget[3] && !cardColor[2]) return false;
                if (!card.costs.includes("레드") && searchTarget[4] && costColor[0]) return false;
                if (!card.costs.includes("옐로") && searchTarget[4] && costColor[1]) return false;
                if (!card.costs.includes("그린") && searchTarget[4] && costColor[2]) return false;
                if (!card.costs.includes("믹스") && searchTarget[4] && costColor[3]) return false;
                if (card.flip && searchTarget[5] && isFlip === 2) return false;
                if (!card.flip && searchTarget[5] && isFlip === 1) return false;
                return true;
            })
            return searchedCards;
        }
        setCardList(getSearchedCards())
    }, [searchTarget, cardType, cookieLevel, cardColor, costColor, isFlip, cardName])

    function resetSearch() {
        sorted = 8
        cardSort(0)
        setCardName("")
        setCardType([false, false, false, false])
        setCookieLevel([false, false, false])
        setCardColor([false, false, false])
        setCostColor([false, false, false, false])
        setFlip(0)
        setSearchTarget([false, false, false, false, false, false])
    }


    function viewCard(index) {
        if (!props.imported) {
            setCurrentCard(index)
            setViewCardModal(true)
        } else {
            props.addCard(index)
        }
    }

    function cardSort(sortType) {
        cardData.sort(sortName)
        cardData.sort(sortColor)
        switch (sortType) {
            case 0:                        // 카드명 정렬
                if (sorted === 0) { cardData.sort(sortNameR); sorted = 4; }
                else { cardData.sort(sortName); sorted = 0; }
                break;
            case 1:                        // 카드 종류 정렬
                if (sorted === 1) { cardData.sort(sortCardTypeR); sorted = 5; }
                else { cardData.sort(sortCardType); sorted = 1; }
                break;
            case 2:                        // 레벨 정렬
                if (sorted === 2) { cardData.sort(sortLevelR); sorted = 6; }
                else { cardData.sort(sortLevel); sorted = 2; }
                break;
            case 3:                        // HP 정렬
                if (sorted === 3) { cardData.sort(sortHPR); sorted = 7; }
                else { cardData.sort(sortHP); sorted = 3; }
                break;
            default:
                break;
        }
        setSearchTarget([...searchTarget])
    }

    function typeChange(checked, i) {
        const a = [...cardType]
        a[i] = checked
        setCardType(a)
        const b = [...searchTarget]
        b[1] = true
        if (!(a[0] || a[1] || a[2] || a[3])) b[1] = false;
        setSearchTarget(b)
    }

    function levelChange(checked, i) {
        const a = [...cookieLevel]
        a[i] = checked
        setCookieLevel(a)
        const b = [...searchTarget]
        b[2] = true
        if (!(a[0] || a[1] || a[2])) b[2] = false
        setSearchTarget(b)
    }

    function colorChange(checked, i) {
        const a = [...cardColor]
        a[i] = checked
        setCardColor(a)
        const b = [...searchTarget]
        b[3] = true
        if (!(a[0] || a[1] || a[2])) b[3] = false
        setSearchTarget(b)
    }

    function costChange(checked, i) {
        const a = [...costColor]
        a[i] = checked
        setCostColor(a)
        const b = [...searchTarget]
        b[4] = true
        if (!(a[0] || a[1] || a[2] || a[3])) b[4] = false
        setSearchTarget(b)
    }

    function flipChange(i) {
        setFlip(i)
        const b = [...searchTarget]
        if (i !== 0) b[5] = true;
        else b[5] = false;
        setSearchTarget(b)
    }

    return (
        <>
            <Container className="mt-3">
                <Form>
                    {
                        !props.imported ? (
                            <Row className="mb-3">
                                <Col>
                                    <h1>카드 정보 검색</h1>
                                </Col>
                            </Row>
                        ) : ""
                    }
                    <Row className="mb-3">
                        <Col>
                            <Form.Group>
                                <Form.Label>카드 이름</Form.Label>
                                <Form.Control type="text" value={cardName} onChange={(e) => { setCardName(e.currentTarget.value) }} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p>카드 종류</p>
                            <ButtonGroup style={{zIndex:"0"}}>
                                <ToggleButton id="cardType-Cookie" type="checkbox" variant="outline-primary" checked={cardType[0]} onChange={(e) => { typeChange(e.currentTarget.checked, 0) }}>COOKIE</ToggleButton>
                                <ToggleButton id="cardType-Item" type="checkbox" variant="outline-primary" checked={cardType[1]} onChange={(e) => { typeChange(e.currentTarget.checked, 1) }}>ITEM</ToggleButton>
                                <ToggleButton id="cardType-Trap" type="checkbox" variant="outline-primary" checked={cardType[2]} onChange={(e) => { typeChange(e.currentTarget.checked, 2) }}>TRAP</ToggleButton>
                                <ToggleButton id="cardType-Stage" type="checkbox" variant="outline-primary" checked={cardType[3]} onChange={(e) => { typeChange(e.currentTarget.checked, 3) }}>STAGE</ToggleButton>
                            </ButtonGroup>
                        </Col>
                        <Col>
                            <p>쿠키 레벨</p>
                            <ButtonGroup style={{zIndex:"0"}}>
                                <ToggleButton id="cookieLevel-1" type="checkbox" variant="outline-primary" checked={cookieLevel[0]} onChange={(e) => { levelChange(e.currentTarget.checked, 0) }}>1</ToggleButton>
                                <ToggleButton id="cookieLevel-2" type="checkbox" variant="outline-primary" checked={cookieLevel[1]} onChange={(e) => { levelChange(e.currentTarget.checked, 1) }}>2</ToggleButton>
                                <ToggleButton id="cookieLevel-3" type="checkbox" variant="outline-primary" checked={cookieLevel[2]} onChange={(e) => { levelChange(e.currentTarget.checked, 2) }}>3</ToggleButton>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p>카드 색상</p>
                            <ButtonGroup style={{zIndex:"0"}}>
                                <ToggleButton id="cardColor-Red" type="checkbox" variant="outline-danger" checked={cardColor[0]} onChange={(e) => { colorChange(e.currentTarget.checked, 0) }}>레드</ToggleButton>
                                <ToggleButton id="cardColor-Yellow" type="checkbox" variant="outline-warning" checked={cardColor[1]} onChange={(e) => { colorChange(e.currentTarget.checked, 1) }}>옐로</ToggleButton>
                                <ToggleButton id="cardColor-Green" type="checkbox" variant="outline-success" checked={cardColor[2]} onChange={(e) => { colorChange(e.currentTarget.checked, 2) }}>그린</ToggleButton>
                            </ButtonGroup>
                        </Col>
                        <Col>
                            <p>코스트 색상</p>
                            <ButtonGroup style={{zIndex:"0"}}>
                                <ToggleButton id="costColor-Red" type="checkbox" variant="outline-danger" checked={costColor[0]} onChange={(e) => { costChange(e.currentTarget.checked, 0) }}>레드</ToggleButton>
                                <ToggleButton id="costColor-Yellow" type="checkbox" variant="outline-warning" checked={costColor[1]} onChange={(e) => { costChange(e.currentTarget.checked, 1) }}>옐로</ToggleButton>
                                <ToggleButton id="costColor-Green" type="checkbox" variant="outline-success" checked={costColor[2]} onChange={(e) => { costChange(e.currentTarget.checked, 2) }}>그린</ToggleButton>
                                <ToggleButton id="costColor-Mix" type="checkbox" variant="outline-secondary" checked={costColor[3]} onChange={(e) => { costChange(e.currentTarget.checked, 3) }}>믹스</ToggleButton>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p>플립 여부</p>
                            <ButtonGroup style={{zIndex:"0"}}>
                                <ToggleButton id="searchTarget[5] && isFlip-All" type="radio" variant="outline-primary" name="isFlip" checked={!searchTarget[5]} onChange={() => { flipChange(0) }}>모두</ToggleButton>
                                <ToggleButton id="searchTarget[5] && isFlip-Yes" type="radio" variant="outline-primary" name="isFlip" checked={searchTarget[5] && isFlip === 1} onChange={() => { flipChange(1) }}>있음</ToggleButton>
                                <ToggleButton id="searchTarget[5] && isFlip-No" type="radio" variant="outline-primary" name="isFlip" checked={searchTarget[5] && isFlip === 2} onChange={() => { flipChange(2) }}>없음</ToggleButton>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    {
                        !props.imported ? (             // 카드 정보 보기 화면
                            <Row className="mb-3 justify-content-center">
                                <Col xs={9}>
                                    <Button id="resetSearch" variant="danger" style={{ width: "100%" }} onClick={() => { resetSearch() }}>필터 초기화</Button>
                                </Col>
                            </Row>
                        ) : (                           // 덱 시뮬레이터에서
                            <Row className="mb-3 justify-content-center">
                                <Col xs={9} className="mb-2">
                                    <Button id="resetSearch" variant="danger" style={{ width: "100%" }} onClick={() => { resetSearch() }}>필터 초기화</Button>
                                </Col>
                                <Col xs={9}>
                                    <Button id="hideDesc" variant="warning" style={{ width: "100%" }} onClick={() => { setDesc(!showDesc) }}>설명 {showDesc ? "숨기기" : "보이기"} </Button>
                                </Col>
                            </Row>
                        )
                    }


                </Form>
                <Row>
                    <Table striped bordered hover style={{ cursor: "pointer" }}>
                        <thead style={{ textAlign: "center" }}>
                            <tr>
                                <th onClick={() => { cardSort(0) }} style={{ width: showDesc?"20%":"auto" }}>카드명</th>
                                <th onClick={() => { cardSort(1) }} style={{ width: "5rem" }}>종류</th>
                                <th onClick={() => { cardSort(2) }} style={{ width: "2rem" }}>LV</th>
                                <th onClick={() => { cardSort(3) }} style={{ width: "2rem" }}>HP</th>
                                {showDesc?(<th>카드 스킬/설명</th>):""}
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                            {
                                cardList.map((card) => {
                                    var desc = card.desc.replace(/<[^>]*>?/g, "")
                                    desc = card.desc.replace(/\n/g, "<br/>")
                                    desc = desc.replace(/\[적\]/g, `<img src=${imgRed} style="width:1rem">`)
                                    desc = desc.replace(/\[황\]/g, `<img src=${imgYellow} style="width:1rem">`)
                                    desc = desc.replace(/\[녹\]/g, `<img src=${imgGreen} style="width:1rem">`)
                                    desc = desc.replace(/\[믹\]/g, `<img src=${imgMix} style="width:1rem">`)
                                    return (
                                        <tr 
                                            style={{userSelect:"none"}} 
                                            id={card.index + card.serial} 
                                            onClick={(e) => { viewCard(card.index);}}
                                            onMouseOver={() => { setTooltipCard(card); setViewTooltip(true); }}
                                            onMouseOut={() => { setTooltipCard({}); setViewTooltip(false); }}
                                            onMouseMove={(e) => { setTooltipCoord([e.clientX, e.clientY]) }}
                                        >
                                            <td style={{ textAlign: "left", color: card.color === "레드" ? "red" : card.color === "옐로" ? "#FFB813" : "green" }}>{card.name}</td>
                                            <td>{card.type}</td>
                                            <td>{card.level ? card.level : "-"}</td>
                                            <td>{card.hp}</td>
                                            {showDesc?(<td dangerouslySetInnerHTML={{ __html: desc }} style={{ textAlign: "left" }}></td>):""}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Row>
            </Container>
            <div style={{ position: "fixed", display: viewTooltip ? "block" : "none", left: tooltipCoord[0] + 20, top: tooltipCoord[1] + 10 }} >
                {tooltipCard.image ? (<img style={{ width: "400px", zIndex: "1000" }} src={require("." + tooltipCard.image[0])} alt={tooltipCard.name} />) : ""}
            </div>
            <Modal show={viewCardModal} onHide={() => { setViewCardModal(false) }} onClick={() => { setViewCardModal(false) }} centered>
                <Container>
                    <Row>
                        {

                            cardData.find(card => card.index === currentCard).image.map(image => {
                                return (
                                    <Col style={{ width: "100%" }}><img src={require(`.${image}`)} alt={cardData.find(card => card.index === currentCard).name} style={{ width: "30rem" }} /></Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </Modal>
        </>
    )

}




export default CardInfo;