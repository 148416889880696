// import { Navbar, Container, Row, Col, Button, Nav, ListGroup, Form, Stack } from "react-bootstrap";

function Home() {

    return (
        <>
            <h1>메인 페이지</h1>
        </>
    )

}

export default Home;