


const sortName = function (a, b) {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
}
const sortNameR = function (a, b) { return -sortName(a, b) }
const sortCardType = function (a, b) {
    var A = 0
    var B = 0
    switch (a.type) {
        case "COOKIE": A = 5; break;
        case "ITEM": A = 4; break;
        case "TRAP": A = 3; break;
        case "STAGE": A = 2; break;
        default: A = 0;
    }
    switch (b.type) {
        case "COOKIE": B = 5; break;
        case "ITEM": B = 4; break;
        case "TRAP": B = 3; break;
        case "STAGE": B = 2; break;
        default: B = 0;
    }
    return B - A;
}
const sortCardTypeR = function (a, b) { return -sortCardType(a, b) }
const sortLevel = function (a, b) {
    return a.level < b.level ? -1 : a.level > b.level ? 1 : 0;
}
const sortLevelR = function (a, b) { return -sortLevel(a, b) }
const sortHP = function (a, b) {
    return a.hp < b.hp ? -1 : a.hp > b.hp ? 1 : 0;
}
const sortHPR = function (a, b) { return -sortHP(a, b) }
const sortColor = function (a, b) {
    var A = 0
    var B = 0
    switch (a.color) {
        case "레드": A = 5; break;
        case "옐로": A = 4; break;
        case "그린": A = 3; break;
        default: A = 0;
    }
    switch (b.color) {
        case "레드": B = 5; break;
        case "옐로": B = 4; break;
        case "그린": B = 3; break;
        default: B = 0;
    }
    return B - A;
}
const sortColorR = function (a, b) { return -sortColor(a, b) }

export {sortName, sortNameR, sortCardType, sortCardTypeR, sortLevel, sortLevelR, sortHP, sortHPR, sortColor, sortColorR}