import { Container, Row, Col, Alert } from "react-bootstrap";
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import cardData from "./carddata.json"
import DeckView from './DeckView'
import CardInfo from "./CardInfo"

const sortedCards = cardData.toSorted((a, b) => (a.index - b.index))
sortedCards.forEach(card => card.count = 0)
let codeLoaded = false;

function DeckSimul() {


    const [deck, setDeck] = useState([])            // card index 배열
    const [deckAnalysis, setAnalysis] = useState({})
    const [alertShow, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState(["", ""])

    const [tooltipCard, setTooltipCard] = useState({})
    const [viewTooltip, setViewTooltip] = useState(false)
    const [tooltipCoord, setTooltipCoordR] = useState([0, 0])

    const deckCode = useParams()
    if (!codeLoaded && deckCode.deckcode) setTimeout(() => { getFromCode(deckCode.deckcode) }, 50)

    const toDeckView = {}
    toDeckView.deck = deck;
    toDeckView.setDeck = setDeck
    toDeckView.deckAnalysis = deckAnalysis;
    toDeckView.setAnalysis = setAnalysis
    toDeckView.deckCode = deckCode.deckcode
    toDeckView.setAlert = setAlert;
    toDeckView.setAlertMessage = setAlertMessage;
    toDeckView.addCardToDeck = addCardToDeck;
    toDeckView.deleteCardFromDeck = deleteCardFromDeck;
    toDeckView.setTooltipCard = setTooltipCard
    toDeckView.setViewTooltip = setViewTooltip
    toDeckView.setTooltipCoordR = setTooltipCoordR

    function alertMsg(msg, type = "danger") {
        setAlertMessage([msg, type])
        setAlert(true)
        setTimeout(() => { setAlert(false) }, 2500)
    }

    function addCardToDeck(index) {
        if (deck.length < 60) {
            if (deckAnalysis.flipCount === 16 && sortedCards[index].flip) {
                alertMsg("FLIP 카드는 16장까지만 덱에 넣을 수 있습니다.")
                return false;
            }
            if (sortedCards[index].count && sortedCards[index].count === 4) {
                alertMsg("같은 번호의 카드는 4장까지만 덱에 넣을 수 있습니다.")
                return false;
            }
            if (sortedCards[index].type !== "COOKIE" && deckAnalysis.typeCount.cookie === 0 && deck.length === 59) {
                alertMsg("덱에 최소 1장의 쿠키를 포함해야 합니다.")
                return false;
            }
            const newDeck = [...deck]
            newDeck.push(index)
            newDeck.sort()
            setDeck(newDeck)
            return true;
        }
        alertMsg("덱에는 60장까지만 넣을 수 있습니다.")
        return false;
    }
    function deleteCardFromDeck(index) {
        if (deck.indexOf(index) > -1) {
            const newDeck = [...deck]
            newDeck.splice(deck.indexOf(index), 1)
            setDeck(newDeck)
            sortedCards[index].count--;
            return true;
        }
        return false;
    }

    function getFromCode(code) {
        codeLoaded = true;
        sortedCards.forEach(card => card.count = 0)
        code = code.replaceAll("_", "/")
        try {
            code = atob(code);
        } catch (err) {
            alertMsg("읽을 수 없는 덱 코드입니다.")
            return;
        }
        const binary = []
        let temp = ""
        for (let c of code) {
            if (temp === "") temp = c;
            else {
                binary.push(temp.charCodeAt(0) * 256 + c.charCodeAt(0))
                temp = ""
            }
        }

        let wallCount = 0;
        binary.forEach(index => {
            if (index === 65535) {
                wallCount++;
                return;
            }
            if (index >= sortedCards.length) {
                wallCount = 4
                return;
            }
        })
        if (wallCount !== 3) {
            alertMsg("읽을 수 없는 덱 코드입니다.")
            return;
        }
        let i = 0;
        const newDeck = [];

        while (binary[i] !== 65535) {
            newDeck.push(binary[i])
            newDeck.push(binary[i])
            newDeck.push(binary[i])
            newDeck.push(binary[i])
            sortedCards[binary[i]].count = 4
            i++
        }
        i++
        while (binary[i] !== 65535) {
            newDeck.push(binary[i])
            newDeck.push(binary[i])
            newDeck.push(binary[i])
            sortedCards[binary[i]].count = 3
            i++
        }
        i++
        while (binary[i] !== 65535) {
            newDeck.push(binary[i])
            newDeck.push(binary[i])
            sortedCards[binary[i]].count = 2
            i++
        }
        i++
        while (i !== binary.length) {
            newDeck.push(binary[i])
            sortedCards[binary[i]].count = 1
            i++
        }

        alertMsg("덱을 불러왔습니다.", "success")

        setDeck(newDeck)

    }

    return (
        <>
            <Container fluid style={{ padding: "0px 2rem" }}>
                <Alert show={alertShow} variant={alertMessage[1]} style={{ position: "fixed", width: "", right: 0 }} >
                    {alertMessage[0]}
                </Alert>
                <Row>
                    <Col lg={7}>
                        <DeckView data={toDeckView} />
                    </Col>
                    <Col lg={5} style={{ height: "90vh", overflow: "auto" }}>
                        <CardInfo imported={true} addCard={addCardToDeck} />
                    </Col>
                </Row>
            </Container>
            <div style={{ position: "fixed", display: viewTooltip ? "block" : "none", left: tooltipCoord[0] + 20, top: tooltipCoord[1] + 10 }} >
                {tooltipCard.image ? (<img style={{ width: "400px", zIndex: "3" }} src={require("." + tooltipCard.image[0])} alt={tooltipCard.name} />) : ""}
            </div>
        </>
    )

}

export default DeckSimul;